import React, {useContext} from "react";
import "./App.css";
import {Route, Routes} from "react-router";
import Overview from "./views/Overview";
import {useLocation} from "react-router-dom";
import Imprint from "./views/Imprint";
import AuthErrorPage from "./views/AuthErrorPage";
import {KeycloakContext} from "./views/utility/KeycloakProvider";

function App() {
  const {keycloak, initialized} = useContext(KeycloakContext);
  const pathname = useLocation().pathname;

  if (pathname == "/imprint") return <Imprint />;

  if (!initialized || !keycloak.authenticated)
    return <AuthErrorPage reason="unauthenticated" />;

  //TODO: activate when updating role concept
  // if (!keycloak.hasResourceRole("SCH Mitglied"))
  // return <AuthErrorPage reason="unauthorized" />;

  return (
    <Routes>
      <Route path="*" element={<Overview />} />
    </Routes>
  );
}

export default App;
