import React, {ReactElement, useEffect, useRef, useState} from "react";
import keycloak from "../../logic/Keycloak";

/**
 * Context that provide access to a global keycloak object (its not a state so changes won't trigger re-renders) and whether it has been initialized.
 *
 * The authenticated-Context is preferable to keycloak.authenticated, as it will trigger re-renders in some cases.
 */
export const KeycloakContext = React.createContext({
  keycloak,
  initialized: false,
  authenticated: false,
});

function KeycloakProvider({children}: {children: ReactElement}) {
  const [initialized, setInitialized] = useState(false);
  const [failedRefreshCount, setFailedRefreshCount] = useState(0);
  const [authLogout, setAuthLogout] = useState(false);
  const refreshRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!initialized) {
      keycloak.onAuthLogout = () => {
        setAuthLogout(true);
      };
      keycloak
        .init({
          onLoad: "check-sso",
          redirectUri: window.location.href,
          pkceMethod: "S256",
        })
        .then(() => {
          setAuthLogout(false);
          refreshRef.current = setInterval(() => {
            keycloak
              .updateToken(90)
              .then(() => {
                setFailedRefreshCount(0);
              })
              .catch(() => {
                setFailedRefreshCount((c) => {
                  if (c > 0 && refreshRef.current) {
                    clearInterval(refreshRef.current);
                    keycloak.clearToken();
                  }
                  return c + 1;
                });
              });
          }, 60000);
        })
        .catch(() => {
          setAuthLogout(true);
        })
        .finally(() => {
          setInitialized(true);
        });
    }
    return () => {
      if (refreshRef.current) clearInterval(refreshRef.current);
    };
  }, []);

  useEffect(() => {
    if (
      initialized &&
      ((!keycloak.authenticated && window.location.pathname != "/imprint") ||
        window.location.pathname.startsWith("/login"))
    )
      keycloak.login({redirectUri: window.location.origin + "/"});
  });

  return (
    <KeycloakContext.Provider
      value={{
        keycloak: keycloak,
        initialized,
        authenticated: !!(
          initialized &&
          !authLogout &&
          failedRefreshCount <= 2 &&
          keycloak.authenticated
        ),
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
}

export default KeycloakProvider;
