import React, {useContext, useEffect, useState} from "react";
import "./AuthErrorPage.css";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@spg/component_library";
import loginBackground from "../images/portalBackground.jpg";
import {KeycloakContext} from "./utility/KeycloakProvider";

/**
 * A screen informing the user about issues with authentication or missing roles.
 */
function AuthErrorPage(props: {reason: "unauthenticated" | "unauthorized"}) {
  const {keycloak} = useContext(KeycloakContext);
  const intl = useIntl();

  const [reduceFlicker, setReduceFlicker] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setReduceFlicker(false), 1000);
    return () => clearTimeout(timeout);
  }, []);

  if (reduceFlicker) return <></>;

  return (
    <div className="authErrorPageContainer">
      <div className="authErrorPageContents">
        <h1 className={`typographyTypoHeadingDesktopH2`}>
          <FormattedMessage id="authErrorPage.title" />
        </h1>
        <div className="authErrorPageMessage">
          <p className="typographyTypoParagraphAllDevicesP">
            <FormattedMessage id={"authErrorPage." + props.reason} />
          </p>
          <div className="authErrorPageButtonContainer">
            {props.reason === "unauthenticated" ? (
              <Button
                label={intl.formatMessage({id: "authErrorPage.login"})}
                idBase={"authErrorPageAction"}
                onClick={() =>
                  keycloak.login({
                    redirectUri: location.href,
                  })
                }
                configuration={"primary"}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="authErrorPageBackgroundContainer"
        style={{backgroundImage: `url(${loginBackground})`}}
      />
    </div>
  );
}

export default AuthErrorPage;
