import {Application, Applications} from "./Types";
import keycloak from "./Keycloak";

/**
 * Enum for REST-request methods
 * @type
 */
type Method = "POST" | "GET" | "PATCH" | "PUT" | "DELETE";

/**
 * API-requests return a promise resolving to this type.
 * @type
 * @property success
 * @property httpCode – Missing if fetching hasn't finished
 * @property unresolved – Whether the request hasn't been made or resolved yet
 * @property errorReason - Missing if successful
 * @property payload - Missing if unsuccessful
 */
type Resolution<T> = {
  success: boolean;
  httpCode?: number;
  unresolved?: boolean;
  errorReason?: string;
  payload?: T;
};

const externalBackends =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
    ? {}
    : {};

/**
 * @namespace API
 * @desc Provides an interface to operations in the backend.
 */
const API = {
  url:
    process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
      ? "http://localhost:8000/portal_backend/"
      : "/portal_backend/",
  /**
   * Fetches a resource using the appropriate headers.
   * @function
   * @param path - relative path of the resource
   * @param method - the method by which the fetch is performed
   * @param withCredentials - whether to include the keycloak authorization header
   * @param body - an optional body to attach to the fetch
   * @param abortController - controller to handle aborting of requests
   * @param backend – specification of another backend
   */
  fetchWithHeaders: (
    path: string,
    method?: Method,
    withCredentials?: boolean,
    body?: object,
    abortController?: AbortController,
    backend?: keyof typeof externalBackends,
  ) => {
    const header: HeadersInit = new Headers();
    header.set("Content-Type", "application/json");
    if (withCredentials)
      header.set("Authorization", "Bearer " + keycloak.token);
    const request: RequestInit = {
      method: method,
      headers: header,
      credentials: "same-origin",
      signal: abortController?.signal,
    };
    if (body) request.body = JSON.stringify(body);
    return fetch(
      (backend ? externalBackends[backend] : API.url) + path,
      request,
    );
  },
  /**
   * Evaluates whether a fetch succeeds.
   * @function
   * @param request - the fetched promise to evaluate
   * @returns a non-rejecting promise
   */
  resolveFetch: async <T>(
    request: Promise<Response>,
  ): Promise<Resolution<T>> => {
    try {
      const response = await request;
      if (response.ok) {
        const len = response.headers.get("content-length");
        if (!len || +len == 0)
          return {success: true, httpCode: response.status};
        try {
          const json = await response.json();
          return {
            success: true,
            httpCode: response.status,
            payload: json,
          };
        } catch (errorReason: unknown) {
          return {
            success: false,
            httpCode: response.status,
            errorReason:
              errorReason instanceof Error
                ? errorReason.message
                : String(errorReason),
          };
        }
      }
      return {
        success: false,
        httpCode: response.status,
        errorReason: response.statusText,
      };
    } catch (errorReason: unknown) {
      return {
        success: false,
        httpCode: 400,
        errorReason:
          errorReason instanceof Error
            ? errorReason.message
            : String(errorReason),
      };
    }
  },
  /**
   * Creates a request with the option to add the keycloak authorization header
   * @function
   * @param useToken - whether to include the keycloak authorization header
   * @param method - the REST-method to use (GET, POST, PUT, PATCH, ...)
   * @param body - a object to include as the request body
   */
  buildRequest: (method: string, useToken?: boolean, body?: object) => {
    const header: HeadersInit = new Headers();
    if (useToken) header.set("Authorization", "Bearer " + keycloak.token);
    const request: RequestInit = {
      method: method,
      headers: header,
    };
    if (body) request.body = JSON.stringify(body);
    return request;
  },
  /**
   * Attempts to get the applications.
   * @function
   */
  getApplications: async (): Promise<Resolution<Applications>> => {
    return fetchedListToDict(
      API.resolveFetch<Application[]>(
        API.fetchWithHeaders("apps", "GET", true),
      ),
    );
  },
};

/**
 * Evaluates whether a fetch for a list succeeds. This list is converted into a dictionary using the attribute 'id' of list elements as keys.
 * @function
 * @param listFetch - a promise that provides the list on resolution
 * @template T the type of items in the list returned by the endpoint and of the values in the returned dict
 */
const fetchedListToDict = async <T extends {id: number}>(
  listFetch: Promise<Resolution<Array<T>>>,
): Promise<Resolution<{[id: number]: T}>> => {
  const resolution = await listFetch;
  if (!resolution.success) return {...resolution, payload: undefined};
  if (!resolution.payload)
    return {
      success: false,
      errorReason: "Unexpected server response (not a list)",
    };
  const dict: {[id: number]: T} = {};
  for (const e of resolution.payload) dict[e.id] = e;
  return {...resolution, payload: dict};
};

export default API;
export {type Resolution};
