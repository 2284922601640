import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import "./index.css";
import App from "./App";
import English from "./lang/en.json";
import German from "./lang/de.json";
import ScreenSizeProvider from "./views/utility/ScreenSize";
import {PortalStyling} from "@spg/component_library/portal";
import {IntlProvider} from "react-intl";
import KeycloakProvider from "./views/utility/KeycloakProvider";

const locale = navigator.language;

let lang = English as Record<string, string>;

if (locale.split("-")[0].toLowerCase() === "de") {
  lang = German as Record<string, string>;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <KeycloakProvider>
    <React.StrictMode>
      <IntlProvider locale={locale} messages={lang}>
        <BrowserRouter>
          <ScreenSizeProvider>
            <>
              <PortalStyling />
              <App />
            </>
          </ScreenSizeProvider>
        </BrowserRouter>
      </IntlProvider>
    </React.StrictMode>
  </KeycloakProvider>,
);
